import styled from 'styled-components'

export const TicketsPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  margin-bottom: -4px;

  iframe {
    width: 100%;
    height: 100vh;
  }
`