import styled from 'styled-components'

export const FaqPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 3rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem 0;

  background: linear-gradient(#5523e5, #071b40);

  h2 {
    margin-bottom: 1.5rem;

    @media only screen and (max-width: 600px) {
      text-align: left;
    }
  }
`

export const FaqQuestion = styled.h3`
  max-width: 550px;
`

export const FaqAnswer = styled.p`
  max-width: 400px;
  text-align: center;

  span {
    color: #ccc;
    display: block;
    margin-top: .25rem;
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
  }
`

export const FaqDevider = styled.div`
  width: 50%;
  height: 1px;
  background-color: var(--off-white);
  margin: 1em auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - .15em);
    top: calc(-.15em + .5px);
    height: .3em;
    width: .3em;
    background-color: white;
    transform: rotateZ(45deg);
  }
`