import styled from 'styled-components'

const FooterContainer = styled.div`
  background-color: #071b40;
  display: grid;
  gap: .25rem 1.5rem;
  grid-template-columns: max-content max-content;
  justify-content: center;
  padding-bottom: 2rem;
  font-size: .8rem;
`



const Footer = () => {
  return (
    <FooterContainer>
      <p>KvK</p>
      <p>81672802</p>

      <p>BTW</p>
      <p>NL003589804B15</p>

      <p>Adres</p>
      <p>Spanbroekerweg 135<br/>1715 GL, Spanbroek</p>
    </FooterContainer>
  )
}

export default Footer