import { FaqAnswer, FaqDevider, FaqPage, FaqQuestion } from "./faq.styled"
import { Link } from 'react-router-dom'

const Faq = () => {
  return (
    <FaqPage id="faq">
      <h2>FAQ</h2>

      <FaqQuestion>Vanaf hoe laat ben ik welkom?</FaqQuestion>
      <FaqAnswer>We verwachten jullie rond 21:00🕘</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Tot hoe laat is het feest</FaqQuestion>
      <FaqAnswer>We zullen door gaan tot in de vroege uurtjes!<span>Dus zorg ervoor dat je genoeg energie hebt ;)</span></FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Wat krijg ik voor mijn ticket?</FaqQuestion>
      <FaqAnswer>Met een ticket krijg je toegang tot het feest. De ticket is <b>all inclusive</b>, je hoeft je dus nergens zorgen om te maken. Nadat je je ticket hebt gescant krijg je gratis drinken en hapjes. Daarnaast hebben we artiesten geregeld zodat je de hele avond kan dansen.💃🕺</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Hoe laat treden de verschillende artiesten op?</FaqQuestion>
      <FaqAnswer>Dit is te vinden in de <Link to="#timetable"><i>Timetable</i></Link> eerder op de pagina.</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Mag ik anderen uitnodigen?</FaqQuestion>
      <FaqAnswer>Als je bent uitgenodigd door Luc of Meike mag je een +1 uitnodigen om (met jou) naar het feest te komen! Super gezellig toch?!🎉</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Welke muziek genres kan ik verwachten?</FaqQuestion>
      <FaqAnswer>We zullen meerdere muziek smaken draaien, denk hierbij aan nederlands hip-hop, dance/techno en hardstyle, maar we hebben ook live optredens! Kortom, je kan schudden met die billen en een half uur later op techno beuken, voor ieder wat wils.<span>(Als je een kaartje koopt vragen we ook welke genres je zou willen horen)</span></FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Is het de bedoeling dat ik verkleed kom?</FaqQuestion>
      <FaqAnswer>Het is niet verplicht om je te verkleden, maar dit zou natuurlijk wel super leuk zijn!</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Is drugs toegestaan?</FaqQuestion>
      <FaqAnswer>Het is niet de bedoeling dat je (soft) drugs meeneemt naar het feest.</FaqAnswer>

      <FaqDevider />

      <FaqQuestion>Tot snel!✨💖</FaqQuestion>
    </FaqPage>
  )
}

export default Faq