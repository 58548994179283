import { ArtistImage, ArtistContainer, ArtistsContainer, ArtistText, ArtistWrapper, TimeTable, TimeTableHeader } from "./info.styled"

import OutOfTheBlue from '../../assets/artists/OutOfTheBlue.jpg'
import DjLuc from '../../assets/artists/DjLuc.jpg'
import Tommy from '../../assets/artists/Tommy.jpg'
import DjDenman from '../../assets/artists/DjDenman.jpg'
import { Link } from "react-router-dom"

const Artists = () => {
  return (
    <>
      <h2>Artiesten</h2>

      <ArtistsContainer id="artists">

        <TimeTable>
          <TimeTableHeader id="timetable">Timetable</TimeTableHeader>

          <Link to="#Tommy">
            <ArtistWrapper>
              <ArtistText>21:00 - Tommy</ArtistText>
            </ArtistWrapper>
          </Link>
          <Link to="#OOTB">
            <ArtistWrapper>
              <ArtistText>22:45 - Out Of The Blue</ArtistText>
            </ArtistWrapper>
          </Link>
          <Link to="#DJDenman">
            <ArtistWrapper>
              <ArtistText>23:30 - DJ Denman</ArtistText>
            </ArtistWrapper>
          </Link>
          <Link to="#DJLuc">
            <ArtistWrapper>
              <ArtistText>01:00 - DJ Luc</ArtistText>
            </ArtistWrapper>
          </Link>
        </TimeTable>

        <ArtistContainer id="OOTB">
          <ArtistImage src={OutOfTheBlue} alt="Out Of The Blue" />
          <ArtistWrapper>
            <ArtistText>Out Of The Blue</ArtistText>
          </ArtistWrapper>
        </ArtistContainer>

        <ArtistContainer id="DJLuc">
          <ArtistImage src={DjLuc} alt="DJ Luc" />
          <ArtistWrapper>
            <ArtistText>DJ Luc</ArtistText>
          </ArtistWrapper>
        </ArtistContainer>

        <ArtistContainer id="Tommy">
          <ArtistImage src={Tommy} alt="Tommy" />
          <ArtistWrapper>
            <ArtistText>Tommy</ArtistText>
          </ArtistWrapper>
        </ArtistContainer>

        <ArtistContainer id="DJDenman">
          <ArtistImage src={DjDenman} alt="DJ Denman" />
          <ArtistWrapper>
            <ArtistText>DJ Denman</ArtistText>
          </ArtistWrapper>
        </ArtistContainer>
      </ArtistsContainer>
    </>
  )
}

export default Artists