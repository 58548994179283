import styled from 'styled-components'
import Background from '../../assets/background.svg'

export const LandingPageContainer = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  padding: 7.5vh 2rem;
  gap: 1.5rem;
`

export const LandingBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;

  background-image: url(${Background});
  background-size: cover;
  width: 100vw;
  height: 100vh;
  background-position: center;

  @media (orientation: landscape) {
    transform: scale(1.225) translateY(-2.7%);
  }
`

export const MainHeaderImage = styled.img`
  width: min(55vw, 55rem);
  height: auto;

  filter: var(--global-drop-shadow);

  @media only screen and (max-width: 800px) {
    width: 80%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 3vh;
  }
`

export const SubHeaderImage = styled(MainHeaderImage)`
  width: min(37vw, 37rem);

  @media only screen and (max-width: 800px) {
    width: 60%;
  }
  @media only screen and (max-width: 600px) {
    width: 80%;
    margin-top: 1vh;
  }
`

export const InformationalTextContainer = styled.div`
  margin-top: auto;
  margin-bottom: -2vh;

  display: flex;
  align-items: center;
  flex-direction: column;
`

export const InfoText = styled.p`
  font-family: 'Biryani', sans-serif;
  font-size: 2.2rem;
  line-height: 1.25em;
  letter-spacing: .04em;
  color: white;
  filter: var(--global-drop-shadow);

  @media only screen and (max-width: 800px) {
    font-size: 1.75rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
`