import styled, { keyframes } from 'styled-components'

const TextAnimation = keyframes`
  0% {
    color: rgb(255,0,0);
  }
  17% {
    color: rgb(255,255,0);
  }
  33% {
    color: rgb(0,255,0);
  }
  50% {
    color: rgb(0,255,255);
  }
  67% {
    color: rgb(0,0,255);
  }
  83% {
    color: rgb(255,0,255);
  }
  100% {
    color: rgb(255,0,0);
  }
`

export const ThankyouText = styled.h1`
  width: 100vw;
  font-size: min(6vw, 3rem);
  text-align: center;
  margin-top: 2vh;
  filter: var(--global-drop-shadow);

  animation: ${TextAnimation} 4s linear infinite;

  @media only screen and (max-width: 600px) {
    margin-top: 10vh;
    font-size: 2rem;
  }

  @media only screen and (min-width: 1921px) {
    margin-top: 10vh;
  }
`

export const ThankYouImage = styled.img`
  width: max(24rem, 24vw);
  height: max(16rem, 16vw);

  @media only screen and (max-width: 450px) {
    width: 81vw;
    height: 56vw;
  }
`

export const ReturnWrapper = styled.div`
  margin-top: auto;
`