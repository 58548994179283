import { InformationalTextContainer, InfoText, LandingBackground, LandingPageContainer, MainHeaderImage, SubHeaderImage } from "./landing.styled"

import DeSkuurFissa from '../../assets/de-skuur-fissa.svg'
import TijdOmTeSpacen from '../../assets/tijd-om-te-spacen.svg'
import Menu from "../menu"

const Landing = () => {
  return (
    <LandingPageContainer>
      <LandingBackground />

      <MainHeaderImage src={DeSkuurFissa} alt="De Skuur Fissa" />
      <SubHeaderImage src={TijdOmTeSpacen} alt="Tijd Om Te Spacen" />

      <Menu />

      <InformationalTextContainer>
        <InfoText>Zaterdag 14 Mei, 21:00</InfoText>
        <InfoText>€ 17,50 entree ALL IN!</InfoText>
      </InformationalTextContainer>
    </LandingPageContainer>
  )
}

export default Landing