import { ImageContainer, PersonalPage, TextContainer } from "./personal.styled"

import LucMeike from '../../assets/Tomorrowland-2021-37-s.jpg'

const Personal = () => {
  return (
    <PersonalPage>
      <TextContainer>
        We hopen jullie snel te zien!🍻🥂
        <br/>
        ~ Luc en Meike
      </TextContainer>

      <ImageContainer src={LucMeike} alt="Luc en Meike" />
    </PersonalPage>
  )
}

export default Personal