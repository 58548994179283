import { TicketsPage } from "./tickets.styled"

const Tickets = () => {
  return (
    <TicketsPage id="tickets">
      <iframe src="https://app.weticket.com/skuur-fissa/de-skuur-fissa?source=embed&sub_source=iframe" title="Kaarten via WeTicket" frameBorder="0"></iframe>
    </TicketsPage>
  )
}

export default Tickets