import { useState } from "react"
import MenuItemComponent from "../MenuItem"
import { MenuContainer } from "./menu.styled"

const Menu = () => {
  const [ BackgroundDirection, setBackgroundDirection ] = useState(false)

  const setDirection = e => {
    const mouseY = e.pageY
    const elHeight = e.target.offsetHeight
    const elStartY = e.target.offsetTop
    const entering = e.type === "mouseenter" ? true : false

    setBackgroundDirection(entering ? mouseY < elStartY + 0.5 * elHeight : mouseY > elStartY + 0.5 * elHeight)
  }

  return (
    <MenuContainer>
      <MenuItemComponent href="#info" updateDirection={setDirection} direction={BackgroundDirection}>
        Informatie
      </MenuItemComponent>
      <MenuItemComponent href="#tickets" updateDirection={setDirection} direction={BackgroundDirection}>
        Kaarten
      </MenuItemComponent>
      <MenuItemComponent href="#faq" updateDirection={setDirection} direction={BackgroundDirection}>
        FAQ
      </MenuItemComponent>
    </MenuContainer>
  )
}

export default Menu