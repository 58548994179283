import styled from 'styled-components'
import { isValidElement, useState } from 'react'

import { Link } from 'react-router-dom'

const MenuItem = styled.div`
  transition: opacity 150ms ease-in-out;
  position: relative;
  padding: .4rem 3rem;
  outline: 1px solid white;
  cursor: pointer;

  div { transform: rotateZ(${p => p.enterTop ? "180deg" : "0deg"}); }
  :hover div { transform: rotateZ(${p => p.enterTop ? "0deg" : "180deg"}); }

  :hover div::after{
    height: 100%;
  }

  :hover p {
    color: black;
  }
`

const ItemBackground = styled.div`
  position: absolute;
  bottom: -1px;
  left: -1px;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  z-index: -1;
  border-radius: inherit;

  ::after {
    content: '';
    position: absolute;
    background-color: white;
    transition: height .4s cubic-bezier(0.8, 0, 0.4, 1);
    height: 0;
    width: 100%;
    border-radius: inherit;
  }
`

const MenuText = styled.p`
  color: white;
  text-transform: uppercase;
  transition: color .4s;
  font-size: 1.5rem;
  margin-bottom: -.35em;
  /* line-height: 2.5rem; */
  font-family: 'Biryani', sans-serif;
  /* transition-delay: .1s; */
  text-align: center;
`

const MenuLink = styled(Link)`

`

const MenuItemComponent = ({ children, href, updateDirection, direction, style, onMouseEnter, onMouseLeave }) => {
  const [ BackgroundDirection, setBackgroundDirection ] = useState(false)

  const setDirection = typeof updateDirection === "function" ? updateDirection : e => {
    const mouseY = e.pageY
    const elHeight = e.target.offsetHeight
    const elStartY = e.target.offsetTop
    const entering = e.type === "mouseenter" ? true : false

    setBackgroundDirection(entering ? mouseY < elStartY + 0.5 * elHeight : mouseY > elStartY + 0.5 * elHeight)
  }

  const mouseEnter = e => {
    setDirection(e)
    if (typeof onMouseEnter === "function") onMouseEnter(e)
  }
  const mouseLeave = e => {
    setDirection(e)
    if (typeof onMouseLeave === "function") onMouseLeave(e)
  }

  const renderChildren = () => {
    if (typeof children === "string") return <MenuText>{ children }</MenuText>
    if (isValidElement(children)) return children
    return <></>
  }

  return (
    <MenuLink to={href}>
      <MenuItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} enterTop={typeof direction === "boolean" ? direction : BackgroundDirection} style={style}>
        <ItemBackground />
        {
          renderChildren()
        }
      </MenuItem>
    </MenuLink>
  )
}

export default MenuItemComponent