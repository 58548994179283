import Main from './components/main'
import ThankYou from './components/thankyou'

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/bedankt" exact element={<ThankYou />} />
        <Route path="/" exact element={<Main />} />
      </Routes>
    </Router>
  )
}

export default App
