import Personal from "../personal"
import Artists from "./artists"
import { InfoPage, InfoText } from "./info.styled"

const Info = () => {
  return (
    <InfoPage id="info">
      <h2>Informatie</h2>

      <InfoText>
        Tijd om te spacen!🌌 Kom met al je maten en besties naar ons schuurfeest voor een avond om nooit te vergeten. De gehele avond krijg je drinken en lekkere hapjes onder genot van live muziek en DJ's🎶
      </InfoText>

      <InfoText>
        We vieren ons feest met het thema galaxy🌌 Je kan onze schuur dus mooi aangekleed verwachten in deze stijl. Zelf mag je je natuurlijk ook aankleden naar het thema.
      </InfoText>

      <Artists />

      <Personal />
    </InfoPage>
  )
}

export default Info