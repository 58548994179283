import styled from 'styled-components'

export const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
  margin-top: 5vh;
  flex-direction: column;

  @media only screen and (max-width: 600px) {
    order: -2;
    margin-top: 0;
  }
`