import styled from 'styled-components'

export const PersonalPage = styled.div`
  width: 90vw;
  margin: 3rem 0;

  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  align-items: center;
  justify-content: center;
`

export const TextContainer = styled.h3`
  font-size: max(1.75rem, 1.5vw);

  min-width: 29.5rem; /* same as artist, but the gap is .5rem larger */
  max-width: min(500px, 80vw);

  @media only screen and (max-width: 800px) {
    font-size: 1.75rem;
    min-width: unset;
  }
`

export const ImageContainer = styled.img`
  width: max(30vw, 30rem);
  height: max(20vw, 20rem);
  box-shadow: var(--global-shadow);

  @media only screen and (max-width: 800px) {
    width: 75vw;
    height: 50vw;
  }
  @media only screen and (max-width: 600px) {
    width: 90vw;
    height: 60vw;
  }
`