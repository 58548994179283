import styled from 'styled-components'

export const InfoPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 3rem 1.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem 0;

  background: linear-gradient(#071b40, #5523e5);

  h2 {
    text-transform: uppercase;
  }
`

export const InfoText = styled.p`
  max-width: 550px;
`

export const ArtistsContainer = styled.div`
  width: 90vw;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
`

export const ArtistContainer = styled.div`
  display: grid;
  grid-template-rows: max(20vw, 20rem) auto;
  width: max(30vw, 30rem);
  border: 2px solid #00AEF7;
  box-shadow: var(--global-shadow);
  transition: filter .3s;

  :hover {
    filter: brightness(1.1);
  }

  @media only screen and (max-width: 600px) {
    width: 90vw;
    grid-template-rows: 60vw auto;
  }
`

export const ArtistImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const ArtistWrapper = styled.div`
  background: linear-gradient(#82b7ff, #4a99ff);
  padding: .8rem 3rem 0 2rem;
  transition: filter .2s ease-in-out;
  cursor: default;
  width: 100%;
  text-align: center;

  :hover {
    filter: brightness(110%);
  }
`

export const ArtistText = styled.p`
  font-family: 'Biryani', sans-serif;
  font-size: max(1.5vw, 1.75rem);
  letter-spacing: .04em;
  text-transform: uppercase;
  text-align: center;

  background: radial-gradient(#3f1ec4, #6b29df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media only screen and (max-width: 600px) {
    font-size: 1.25rem;
  }
`

export const TimeTable = styled.div`
  max-width: 800px;
  margin-left: calc(calc(90vw - 800px) / 2);
  margin-right: calc(calc(90vw - 800px) / 2);

  display: flex;
  flex-direction: column;
  gap: .5rem 0;

  @media only screen and (max-width: 880px) {
    margin: 0;
  }
`

export const TimeTableHeader = styled(ArtistText)`
  color: white;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  padding-top: .35em;
`