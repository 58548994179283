import { LandingBackground, LandingPageContainer, MainHeaderImage, SubHeaderImage } from "../landing/landing.styled"
import { ThankyouText, ReturnWrapper, ThankYouImage } from "./thankyou.styled"

import DeSkuurFissa from '../../assets/de-skuur-fissa.svg'
import TijdOmTeSpacen from '../../assets/tijd-om-te-spacen.svg'
import MenuItemComponent from "../MenuItem"

import Polonaise from '../../assets/Tomorrowland-2021-46-s.jpg'

const ThankYou = () => {
  return (
    <LandingPageContainer>
      <LandingBackground />

      <MainHeaderImage src={DeSkuurFissa} alt="De Skuur Fissa" />
      <SubHeaderImage src={TijdOmTeSpacen} alt="Tijd Om Te Spacen" />

      <ThankyouText>Gezellig dat je komt!✨</ThankyouText>

      <ThankYouImage src={Polonaise} alt="Foto van vorige Skuur Fissa" />

      <ReturnWrapper>
        <MenuItemComponent href="/">Terug naar site</MenuItemComponent>
      </ReturnWrapper>
    </LandingPageContainer>
  )
}

export default ThankYou