import Landing from './landing'
import Info from './info'
import Tickets from './tickets'
import Faq from './faq'

import styled from 'styled-components'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from './footer'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;

  overflow-x: hidden;
  position: relative;
`

const Main = () => {
  const location = useLocation()

  useEffect(() => {
    if (location.hash) {
      document.getElementById(location.hash.substring(1)).scrollIntoView()
    } else {
      window.scrollTo(0,0)
    }
  }, [location])

  return (
    <MainContainer>
      <Landing />
      <Info />
      <Tickets />
      <Faq />
      <Footer />
    </MainContainer>
  )
}

export default Main
